import { default as contactkkOuvHuhQaMeta } from "/vercel/path0/src/pages/contact.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as projectsdxcLVq4i3YMeta } from "/vercel/path0/src/pages/projects.vue?macro=true";
export default [
  {
    name: contactkkOuvHuhQaMeta?.name ?? "contact",
    path: contactkkOuvHuhQaMeta?.path ?? "/contact",
    meta: contactkkOuvHuhQaMeta || {},
    alias: contactkkOuvHuhQaMeta?.alias || [],
    redirect: contactkkOuvHuhQaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: projectsdxcLVq4i3YMeta?.name ?? "projects",
    path: projectsdxcLVq4i3YMeta?.path ?? "/projects",
    meta: projectsdxcLVq4i3YMeta || {},
    alias: projectsdxcLVq4i3YMeta?.alias || [],
    redirect: projectsdxcLVq4i3YMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/projects.vue").then(m => m.default || m)
  }
]