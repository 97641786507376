import revive_payload_client_yzARYcdAN7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.16.19_eslint@8.45.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_7rEkGzZ2s6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.16.19_eslint@8.45.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_7zNmuBaWmG from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_PpRnC3lE9R from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.16.19_eslint@8.45.0_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_tJwERSy8ej from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.16.19_eslint@8.45.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_7mNpmSDdrY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.6/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_client_B86mNmBton from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_g8l3MZfQku from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@18.16.19_eslint@8.45.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vercel_W9mQen5XeM from "/vercel/path0/src/plugins/vercel.ts";
export default [
  revive_payload_client_yzARYcdAN7,
  router_7rEkGzZ2s6,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_PpRnC3lE9R,
  prefetch_client_tJwERSy8ej,
  plugin_client_7mNpmSDdrY,
  plugin_client_B86mNmBton,
  chunk_reload_client_g8l3MZfQku,
  vercel_W9mQen5XeM
]